import React from "react";
import Picker from "@emoji-mart/react";
import "../../../App.css";
import {
    newChatBoxIcon,
    newChatBoxIcon2,
    LinkBlue,
    newChatBoxIcon3,
    newChatBoxIcon4,
    newChatBoxIcon5,
    AddNameBlue,
    ImageBlue,
    GIFBlue,
    EmojiBlue,
    newChatBoxIconRestricted3,
    newChatBoxIconRestricted5
} from "../../../assets/svg-icon/index";
import { maximumMediaSize } from "../../../config/constants/MaximumMediaSize";
import { InboxChatboxInputCharCount } from "../";
import { connect } from "react-redux";
import {
    setImageLoadingStatus,
    getGiyf,
    getSuggestionGiyf,
    createShortenUrl,
    getMe
} from "../../../redux/actions";
import { errorToaster } from "../../../utils/loggerUtils";
import Giyf from "../../Giyf/Giyf";
import Link from "../../Link/Link";
import Config from "../../../config";
import { MMSStatus } from "../../../constant";
import CustomToolTip from "./Tooptip";
import { ImageBox } from "../../ImageBox/ImageBox";
import { isDomainExist, validateUrl } from "../../../utils/urlUtils";
const MAX_COUNT = 1000;
const hoverKeys = {
    AddNameHover: "AddNameHover",
    LinkHover: "LinkHover",
    PictureHover: "PictureHover",
    EmojiHover: "EmojiHover",
    GiyfHover: "GiyfHover"
};
class InboxChatboxInput extends React.Component {
    hashMaps = {};
    constructor(props) {
        super(props);
        this.state = {
            inputFocus: false,
            messageText: "",
            textAreaRows: 1,
            showEmoji: false,
            mediaObject: null,
            showGiyf: false,
            showLinkBox: false,
            hoverEffect: {
                AddNameHover: false,
                LinkHover: false,
                PictureHover: false,
                EmojiHover: false,
                GiyfHover: false
            },
            getUserData: false
        };
        this.emojiIconRef = React.createRef();
        this.linkIconRef = React.createRef();
        this.giyfIconRef = React.createRef();
        this.linkRef = React.createRef();
        this.giyfRef = React.createRef();
        this.emojiRef = React.createRef();
    }
    getUserData = () => {
        this.props.getMe();
        this.setState({
            getUserData: false
        });
    };
    componentDidMount() {
        this.getUserData();
    }
    componentWillUnmount() {
        this.setState({
            getUserData: true
        });
    }
    componentDidUpdate() {
        if (this.state.getUserData) {
            this.getUserData();
        }
    }
    handleInputClick = (e) => {
        this.setState({ inputFocus: true });
        this.bindDocumentListener();
    };
    stopPropagation = (event) => {
        event.stopPropagation();
        event.preventDefault();
    };
    bindDocumentListener = () => {
        document.getElementById("root").addEventListener("click", this.documentClick, false);
    };

    unbindDocumentListener = () => {
        document.getElementById("root").removeEventListener("click", this.documentClick, false);
    };
    documentClick = (e) => {
        if (
            this.emojiIconRef.current &&
            !this.emojiIconRef.current.contains(e.target) &&
            this.emojiRef.current &&
            !this.emojiRef.current.contains(e.target)
        ) {
            this.setState({ showEmoji: false });
        }
        if (
            this.linkIconRef.current &&
            !this.linkIconRef.current.contains(e.target) &&
            this.linkRef.current &&
            !this.linkRef.current.contains(e.target)
        ) {
            this.setState({ showLinkBox: false });
        }
        if (
            this.giyfIconRef.current &&
            !this.giyfIconRef.current.contains(e.target) &&
            this.giyfRef.current &&
            !this.giyfRef.current.contains(e.target)
        ) {
            this.setState({ showGiyf: false });
        }
        this.setState({ inputFocus: false });
    };
    messageTextUrl = () => {
        // e.preventDefault();
        let textWords = this.state.messageText.split(" ");
        textWords = textWords.map((eachWord) => {
            let shouldUpdate = validateUrl(eachWord);
            if (shouldUpdate) {
                shouldUpdate = isDomainExist(eachWord);
            }
            if (shouldUpdate) {
                // REPLACE LOGIC
                let exist = this.hashMaps.hasOwnProperty(eachWord);
                if (!exist) {
                    this.handleShortenUrlClick(eachWord).then((res) => {
                        this.hashMaps[`${eachWord}`] = res;

                        // return res
                    });
                } else {
                    return this.hashMaps[`${eachWord}`];
                }
            }
            return eachWord;
        });

        let filteredValues = textWords.join(" ");
        this.setState({
            messageText: filteredValues
        });
    };
    handleChange = (e) => {
        // OLD LOGIC
        // e.preventDefault();
        // this.setState({
        //     messageText: e.target.value
        // });

        e.preventDefault();
        let textWords = e.target.value.split(" ");
        textWords = textWords.map((eachWord) => {
            let shouldUpdate = validateUrl(eachWord);
            if (shouldUpdate) {
                shouldUpdate = isDomainExist(eachWord);
            }
            if (shouldUpdate) {
                // REPLACE LOGIC
                let exist = this.hashMaps.hasOwnProperty(eachWord);
                if (!exist) {
                    this.handleShortenUrlClick(eachWord).then((res) => {
                        this.hashMaps[`${eachWord}`] = res;

                        // return res
                        this.messageTextUrl();
                    });
                } else {
                    return this.hashMaps[`${eachWord}`];
                }
            }
            return eachWord;
        });

        let filteredValues = textWords.join(" ");
        this.setState({
            messageText: filteredValues
        });
    };

    handleEmojiClick = () => {
        this.setState({ showEmoji: !this.state.showEmoji });
        if (!this.state.inputFocus) {
            this.bindDocumentListener();
        }
    };
    handleEmojiSelect = (emoji) => {
        let { messageText } = this.state;
        if (messageText.length + 1 < MAX_COUNT) {
            messageText += `${emoji.native}`;
            this.setState({ messageText });
        }
    };
    handleInputKeyDown = (e) => {
        if (e.keyCode === 13 && !e.shiftKey) {
            e.preventDefault();
            this.handleMessageSend();
        } else if (e.keyCode === 13 && e.shiftKey) {
            if (this.state.textAreaRows < 2) {
                this.setState((state) => ({
                    textAreaRows: state.textAreaRows + 1
                }));
            }
        } else if (e.keyCode === 8) {
            this.setState({
                textAreaRows: 1
            });
        }
    };
    handleClearStateMessageBox = () => {
        this.setState({ messageText: "", textAreaRows: 1 });
        this.clearFileInput();
    };
    handleMessageSend = (e) => {
        e && e.preventDefault();
        let { messageText } = this.state;
        if (!!messageText.trim().length) {
            // messageText = messageText.replace(/\r?\n/g, " <br/> ");
            this.props.onMessageSend(messageText, this.state.mediaObject);
            this.setState({ messageText: "", textAreaRows: 1 });
            this.clearFileInput();
            this.props.setImageLoadingStatus(true);
        } else {
            if (this.state.mediaObject !== null) {
                this.props.onMessageSend(messageText, this.state.mediaObject);
                this.setState({ messageText: "", textAreaRows: 1 });
                this.clearFileInput();
                this.props.setImageLoadingStatus(true);
            }
        }
    };
    openImageDialog = () => {
        this.inputFileReference.click();
    };
    _handleImageChange = (e) => {
        e.preventDefault();
        if (e.target.files[0].size / 1000000 > maximumMediaSize) {
            errorToaster("file size shouldn't exceed 1MB");
            return;
        }
        let fileReader = new FileReader();
        fileReader.readAsDataURL(e.target.files[0]);
        this.setState({
            mediaObject: {
                Obj: e.target.files[0],
                type: "image",
                name: e.target.files[0].name
            }
        });
    };
    updateMediaObjectGiyf = (Obj) => {
        this.setState({ mediaObject: Obj });
    };
    clearFileInput = () => {
        this.setState({
            mediaObject: null
        });
        if (this.inputFileReference) {
            this.inputFileReference.value = null;
        }
    };
    openGiffDialog = () => {
        this.setState({
            showGiyf: !this.state.showGiyf
        });
        if (!this.state.inputFocus) {
            this.bindDocumentListener();
        }
    };
    openLinkDialog = () => {
        this.setState({
            showLinkBox: !this.state.showLinkBox
        });
        if (!this.state.inputFocus) {
            this.bindDocumentListener();
        }
    };
    addLinkInTextMessage = async (link) => {
        let messageText = await this.handleShortenUrlClick(link);
        if (messageText.length + 1 < MAX_COUNT) {
            this.setState({
                showLinkBox: false,
                messageText: this.state.messageText + messageText
            });
        }
    };
    handleShortenUrlClick = (url) => {
        return new Promise((resolve, reject) => {
            this.props
                .createShortenUrl(url)
                .then((res) => {
                    // let messageText = this.state.messageText;
                    let text = `${Config.env().SHORTEN_URL_DOMAIN}/url/${res.Code}`;
                    resolve(text);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    };
    addFirstNameInTextMessage = () => {
        if (this.state.messageText.length + this.props.name.length < MAX_COUNT) {
            let messageText = this.state.messageText;
            messageText += `${this.props.name}`;
            this.setState({
                messageText: messageText
            });
        }
    };
    onHoverEffect = (key, value) => {
        let hoverEffect = { ...this.state.hoverEffect };
        if (value) {
            Object.keys(hoverEffect).forEach((val) => {
                hoverEffect[val] = false;
                return;
            });
        }
        hoverEffect[`${key}`] = value;
        this.setState({
            hoverEffect
        });
    };
    render() {
        const { messageText } = this.state;
        return (
            <>
                <form onSubmit={(e) => e.preventDefault()}>
                    <div className="inbox-chatbox-input-wrapper" onClick={this.handleInputClick}>
                        <div className="inbox-chatbox-input">
                            <textarea
                                placeholder="Type your message here…"
                                value={messageText}
                                maxLength={MAX_COUNT}
                                rows={this.state.textAreaRows}
                                onScroll={(e) => e.stopPropagation()}
                                onKeyDown={this.handleInputKeyDown}
                                onChange={this.handleChange}
                            ></textarea>
                        </div>
                    </div>
                </form>
                <div className="emojisection">
                    <ul>
                        <ImageBox
                            mediaObject={this.state.mediaObject}
                            isImageUploading={this.props.isImageUploading}
                            clearFileInput={this.clearFileInput}
                        />
                        <CustomToolTip text={"Personalization"}>
                            <li
                                className="tooltip personalize-tool-tip"
                                onMouseEnter={() =>
                                    this.onHoverEffect(hoverKeys.AddNameHover, true)
                                }
                                onMouseLeave={() =>
                                    this.onHoverEffect(hoverKeys.AddNameHover, false)
                                }
                            >
                                <img
                                    src={
                                        this.state.hoverEffect.AddNameHover
                                            ? AddNameBlue
                                            : newChatBoxIcon
                                    }
                                    onClick={() => this.addFirstNameInTextMessage()}
                                    className="img-fluid min-width-30"
                                    alt="img"
                                />
                            </li>
                        </CustomToolTip>
                        <CustomToolTip text={"Link"}>
                            <li
                                className="tooltip"
                                onClick={this.openLinkDialog}
                                ref={this.linkIconRef}
                                onMouseEnter={() => this.onHoverEffect(hoverKeys.LinkHover, true)}
                                onMouseLeave={() => this.onHoverEffect(hoverKeys.LinkHover, false)}
                            >
                                <div ref={this.linkRef} onClick={(e) => this.stopPropagation(e)}>
                                    <Link
                                        marginLeft={30}
                                        showLinkBox={this.state.showLinkBox}
                                        addLinkInText={this.addLinkInTextMessage}
                                    />
                                </div>
                                <img
                                    src={
                                        this.state.hoverEffect.LinkHover || this.state.showLinkBox
                                            ? LinkBlue
                                            : newChatBoxIcon2
                                    }
                                    className="img-fluid min-width-30"
                                    alt="img"
                                />
                            </li>
                        </CustomToolTip>

                        <CustomToolTip text={"Emojis"}>
                            <li
                                className="tooltip"
                                style={{ position: "relative" }}
                                onClick={this.handleEmojiClick}
                                onMouseEnter={() => this.onHoverEffect(hoverKeys.EmojiHover, true)}
                                onMouseLeave={() => this.onHoverEffect(hoverKeys.EmojiHover, false)}
                            >
                                <div
                                    ref={this.emojiRef}
                                    style={{ display: this.state.showEmoji ? "block" : "none" }}
                                >
                                    <div
                                        onClick={(e) => this.stopPropagation(e)}
                                        style={{
                                            position: "absolute",
                                            right: 0,
                                            bottom: "30px"
                                        }}
                                    >
                                        <Picker
                                            onEmojiSelect={this.handleEmojiSelect}
                                            set="apple"
                                            theme="light"
                                        />
                                    </div>
                                </div>

                                <img
                                    ref={this.emojiIconRef}
                                    src={
                                        this.state.hoverEffect.EmojiHover
                                            ? EmojiBlue
                                            : newChatBoxIcon4
                                    }
                                    className="img-fluid min-width-30"
                                    alt="img"
                                />
                            </li>
                        </CustomToolTip>
                        {this.props.user &&
                        this.props.user.Company &&
                        this.props.user.Company.MMSStatus === MMSStatus.Yes ? (
                            <CustomToolTip text={"Image"}>
                                <li
                                    className="tooltip"
                                    onClick={this.openImageDialog}
                                    onMouseEnter={() =>
                                        this.onHoverEffect(hoverKeys.PictureHover, true)
                                    }
                                    onMouseLeave={() =>
                                        this.onHoverEffect(hoverKeys.PictureHover, false)
                                    }
                                >
                                    <input
                                        ref={(ref) => (this.inputFileReference = ref)}
                                        onChange={(e) => this._handleImageChange(e)}
                                        type="file"
                                        id="my_file"
                                        style={{ display: "none" }}
                                    />
                                    <img
                                        src={
                                            this.state.hoverEffect.PictureHover
                                                ? ImageBlue
                                                : newChatBoxIcon3
                                        }
                                        className="img-fluid min-width-30"
                                        alt="img"
                                    />
                                </li>{" "}
                            </CustomToolTip>
                        ) : (
                            <CustomToolTip text={"Upgrade your account to start sending images"}>
                                <li
                                    className="tooltip"
                                    onMouseEnter={() =>
                                        this.onHoverEffect(hoverKeys.PictureHover, true)
                                    }
                                    onMouseLeave={() =>
                                        this.onHoverEffect(hoverKeys.PictureHover, false)
                                    }
                                >
                                    <img
                                        src={newChatBoxIconRestricted3}
                                        className="img-fluid min-width-30"
                                        alt="img"
                                    />
                                </li>
                            </CustomToolTip>
                        )}
                        {this.props.user &&
                        this.props.user.Company &&
                        this.props.user.Company.MMSStatus === MMSStatus.Yes ? (
                            <CustomToolTip text={"GIFs"}>
                                <li
                                    className="tooltip"
                                    style={{ position: "relative" }}
                                    onClick={this.openGiffDialog}
                                    onMouseEnter={() =>
                                        this.onHoverEffect(hoverKeys.GiyfHover, true)
                                    }
                                    onMouseLeave={() =>
                                        this.onHoverEffect(hoverKeys.GiyfHover, false)
                                    }
                                >
                                    <div ref={this.giyfRef}>
                                        <Giyf
                                            showGiyf={this.state.showGiyf}
                                            getGiyf={this.props.getGiyf}
                                            getSuggestionGiyf={this.props.getSuggestionGiyf}
                                            updateMediaObj={this.updateMediaObjectGiyf}
                                            inputFileReference={this.inputFileReference}
                                            openGiffDialog={this.openGiffDialog}
                                            className={"gif-modal"}
                                        />
                                    </div>
                                    <img
                                        src={
                                            this.state.hoverEffect.GiyfHover
                                                ? GIFBlue
                                                : newChatBoxIcon5
                                        }
                                        ref={this.giyfIconRef}
                                        className="img-fluid min-width-30"
                                        alt="img"
                                    />
                                </li>{" "}
                            </CustomToolTip>
                        ) : (
                            <CustomToolTip text={"Upgrade your account to start sending GIFS"}>
                                <li
                                    className="tooltip"
                                    style={{ position: "relative" }}
                                    onMouseEnter={() =>
                                        this.onHoverEffect(hoverKeys.GiyfHover, true)
                                    }
                                    onMouseLeave={() =>
                                        this.onHoverEffect(hoverKeys.GiyfHover, false)
                                    }
                                >
                                    <img
                                        src={newChatBoxIconRestricted5}
                                        className="img-fluid min-width-30"
                                        alt="img"
                                    />
                                </li>
                            </CustomToolTip>
                        )}
                        <li className="width-120">
                            <InboxChatboxInputCharCount
                                charCount={messageText.length}
                                maxCount={MAX_COUNT}
                            />
                        </li>
                        <li className="float-right mr-30">
                            <button onClick={this.handleMessageSend} className="msgreplybtn">
                                Reply
                            </button>
                        </li>
                    </ul>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isImageUploading: state.chat.isImageUploading,
        user: state.auth.user
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setImageLoadingStatus: (status) => dispatch(setImageLoadingStatus(status)),
        getGiyf: (query, limit, offset) => dispatch(getGiyf(query, limit, offset)),
        getSuggestionGiyf: (query) => dispatch(getSuggestionGiyf(query)),
        createShortenUrl: (url) => dispatch(createShortenUrl(url)),

        getMe: () => dispatch(getMe())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(InboxChatboxInput);
